@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700 &display=swap");

@font-face {
  font-family: 'neulis_altlight';
  src: url('../../../public/fonts/neulisalt-light-webfont.eot');
  src: url('../../../public/fonts/neulisalt-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/neulisalt-light-webfont.woff2') format('woff2'),
    url('../../../public/fonts/neulisalt-light-webfont.woff') format('woff'),
    url('../../../public/fonts/neulisalt-light-webfont.ttf') format('truetype'),
    url('../../../public/fonts/neulisalt-light-webfont.svg#neulis_altlight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'neulis_altmedium';
  src: url('../../../public/fonts/neulisalt-medium-webfont.eot');
  src: url('../../../public/fonts/neulisalt-medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/neulisalt-medium-webfont.woff2') format('woff2'),
    url('../../../public/fonts/neulisalt-medium-webfont.woff') format('woff'),
    url('../../../public/fonts/neulisalt-medium-webfont.ttf') format('truetype'),
    url('../../../public/fonts/neulisalt-medium-webfont.svg#neulis_altmedium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'neulis_altregular';
  src: url('../../../public/fonts/neulisalt-regular-webfont.eot');
  src: url('../../../public/fonts/neulisalt-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/neulisalt-regular-webfont.woff2') format('woff2'),
    url('../../../public/fonts/neulisalt-regular-webfont.woff') format('woff'),
    url('../../../public/fonts/neulisalt-regular-webfont.ttf') format('truetype'),
    url('../../../public/fonts/neulisalt-regular-webfont.svg#neulis_altregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'neulis_altsemibold';
  src: url('../../../public/fonts/neulisalt-semibold-webfont.eot');
  src: url('../../../public/fonts/neulisalt-semibold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/neulisalt-semibold-webfont.woff2') format('woff2'),
    url('../../../public/fonts/neulisalt-semibold-webfont.woff') format('woff'),
    url('../../../public/fonts/neulisalt-semibold-webfont.ttf') format('truetype'),
    url('../../../public/fonts/neulisalt-semibold-webfont.svg#neulis_altsemibold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'neulis_altsemibold_italic';
  src: url('../../../public/fonts/neulisalt-semibolditalic-webfont.eot');
  src: url('../../../public/fonts/neulisalt-semibolditalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/neulisalt-semibolditalic-webfont.woff2') format('woff2'),
    url('../../../public/fonts/neulisalt-semibolditalic-webfont.woff') format('woff'),
    url('../../../public/fonts/neulisalt-semibolditalic-webfont.ttf') format('truetype'),
    url('../../../public/fonts/neulisalt-semibolditalic-webfont.svg#neulis_altsemibold_italic') format('svg');
  font-weight: normal;
  font-style: normal;
}

.neulist-regular {
  font-family: 'neulis_altregular', sans-serif;
}

.neulist-sb-italic {
  font-family: 'neulis_altsemibold_italic', sans-serif;
}

* [class^="icon-"],
.Flight-vm-table table tr td {
  vertical-align: middle;
}

body {
  background-color: #fff !important;
  font-family: Manrope, sans-serif !important;
  font-size: 14px !important;
  position: relative;
}

ul,
ul li {
  margin: 0;
  padding: 0;
}

a:hover,
body {
  color: #2a2a2e;
}

a:hover {
  text-decoration: none;
}

.Flight-mainContainer nav ul li,
ul,
ul li {
  list-style: none;
}

.Flight-bgg-info:after,
.Flight-mainContainer nav ul li,
.Flight-timeblock.filterslot .Flight-stopp.Flight-filter-selected .Flight-wht-icon {
  display: inline-block;
}

.Flight-left-sidebar .Flight-back-btn,
.Flight-logo-sec .title,
.Flight-selection-bar span {
  text-transform: capitalize;
}

.Flight-btn-gradient:hover,
.Flight-btn-purple:hover,
.Flight-pax-calc.Flight-val-plus .btn,
.Flight-selector-box-flight .Flight-room-cls .Flight-qty-box .input-group .Flight-val-plus .btn,
.Flight-val-plus .Flight-pax-num,
a {
  color: #fff;
}

.Flight-font20 {
  font-size: 20px !important;
}

.Flight-font18 {
  font-size: 18px !important;
}

.Flight-font16 {
  font-size: 16px !important;
}
.Flight-font15{font-size:15px !important;}

.Flight-font14 {
  font-size: 14px !important;
}

.Flight-font13 {
  font-size: 13px !important;
}

.Flight-font12 {
  font-size: 12px !important;
}

.Flight-font11,
.Flight-roundtrip-result .Flight-sorting {
  font-size: 11px;
}

.Flight-font10 {
  font-size: 10px;
}

.Flight-font-medium,
.pmt-pnr h4 {
  font-weight: 500 !important;
}

.Flight-font-semibold {
  font-weight: 600 !important;
}

.Flight-font-bold {
  font-weight: 700 !important;
}

.Flight-theme-txt,
.Flight-theme-txt:hover {
  color:#4B2269 !important;
}

.Flight-cursor-pointer,
.Flight-roundtrip-result .Flight-search-result,
.Flight-stops-slot .Flight-stopp {
  cursor: pointer;
}

button.Flight-btn {
  min-width: 80px;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
}

.Flight-trav-list tr td:last-child,
.Flight-txt-gray {
  color: #666;
}
.text-gray2{color:#49454F}

.Flight-home-padding,
.Flight-selector-box-flight .flight-class {
  padding: 15px;
}

.Flight-form-control:focus,
.form-check-input:focus {
  box-shadow: none !important;
}

.btn-link, .headerwrapper.navOnly nav ul li a.active, .profile-dd:hover {
  color: #6900a0;
}
.dropdown-toggle.profile-dd:hover:after {
  border-color: #6900a0;
}

.btn.Flight-btn-gradient {
  background-color: #6900a0;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  max-width: 344px;
  width: 100%;
  line-height: 28px;
  overflow: hidden;
}

.Flight-widgetSection .form-group {
  margin-bottom: 0;
  position: relative;
}

.Flight-widgetSection {
  margin: 0 auto;
  position: relative;
  z-index: 10;
  border-radius: 12px;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 10%);
  background-color: #fafafa;
  padding: 25px 20px;
}

.Flight-roundtrip-result .Flight-airport-part .Flight-airport-name h4,
.Flight-roundtrip-result .Flight-book-flight .Flight-show-detail,
.Flight-roundtrip.hide,
.Flight-show-detail .showdetail,
.Flight-timeblock.filterslot .Flight-stopp.Flight-filter-selected .Flight-g-icon,
.Flight-wht-icon,
.collapsed.Flight-show-detail .Flight-hidedetail,
.flight-info-wrap .flight-info:last-child:after,
.flight-info-wrap .flight-info:last-child:before,
.Flight-datepicker .react-datepicker-popper[data-placement^="bottom"] .Flight-datepicker .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  display: none;
}

button:focus {
  outline: 0;
}

.Flight-roundtrip-result .Flight-logo-sec .title {
  font-size: 14px;
}

.Flight-select-fare .form-check {
  padding-left: 28px;
  padding-right: 10px;
  min-height: auto;
  margin-bottom: 0;
  line-height: 24px;
}
.radio_animated:checked:after {
  border-color: #4B2269;
}

.Flight-select-fare {
  margin-left: 5px;
  margin-top: 0;
}

.Flight-inner-page-search .custom-phone-none,
.bookFlightDetail {
  display: flex;
}

.Flight-btn-detail li:last-child {
  border-right: none;
}

.Flight-ph-p {
  padding-left: 12px !important;
}

.Flight-w-100 {
  min-width: 100% !important;
}

button.Flight-search-flight-btn {
  background-color:#690DA0;
  /* background: linear-gradient(0deg,#993cc8,#b566de); */
  border-radius: 8px;
  font-weight: 700;
  height: 60px;
  position: relative;
  transition:0.3s ease-in-out;
  z-index: 0;
  width: 300px;
  overflow: hidden;
}
button.Flight-search-flight-btn:hover, button.Flight-search-flight-btn:active{ background-color: #632D8B !important;}


.Flight-pax-arrow img {
  left: auto !important;
  right: 6px !important;
  top: 19px !important;
}

.Flight-selector-box-flight {
  text-align: left;
  padding: 10px;
  position: absolute;
  width: 100%;
  background-color: #fff;
  z-index: 3;
  border: 1px solid #d9d9d9;
  border-radius: 7px;
  -webkit-box-shadow: 0 2px 14px 0 rgb(0 0 0 / 4%);
  box-shadow: 0 2px 14px 0 rgb(0 0 0 / 4%);
  top: 50px;
  display: none;
}

.Flight-overlayshow.Flight-overlay,
.Flight-roundtrip.show,
.Flight-selector-box-flight.show,
.Flight-show-detail .Flight-hidedetail,
.collapsed.Flight-show-detail .showdetail,
.Flight-datepicker .react-datepicker-wrapper {
  display: block;
}

.Flight-btn:focus,
.Flight-form-control:focus {
  outline: 0;
  box-shadow: none;
  border: 1px solid #6900a0;
}

button.Flight-sm-btn:first-child:active {
  background-color: #6900a0;
  border-color: #6900a0;
  color: #fff;
}

.Flight-widgetSection .form-group .Flight-search-block .form-control,
.btn.Flight-show-detail:focus {
  border: none;
}
.Flight-show-detail:hover{text-decoration:underline; transition: 0.3s ease-in-out;}
.Flight-selector-box-flight .Flight-room-cls .Flight-qty-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 12px;
  justify-content: space-between;
}

.Flight-selector-box-flight .Flight-room-cls .Flight-qty-box .input-group {
  width: auto;
  margin-left: 15px;
}

.Flight-selector-box-flight .Flight-room-cls .Flight-qty-box label {
  text-transform: uppercase;
  font-weight: 700;
  margin-right: 0;
  font-size: 15px !important;
  line-height: 18px !important;
  margin-bottom: 0;
}

.Flight-selector-box-flight .Flight-room-cls .Flight-qty-box .input-group .form-control {
  font-weight: 700;
  padding: 0;
  font-size: 16px;
  text-align: center;
  border: none;
  height: 28px;
}

.Flight-selector-box-flight .Flight-room-cls .Flight-qty-box .input-group .btn {
  min-width: auto;
  line-height: initial;
  border-radius: 0;
  border: none;
  background-color: transparent;
  font-size: 22px;
  width: 26px;
  color: #848a8d;
  padding: 5px 0;
}

.Flight-selector-box-flight .Flight-room-cls .Flight-qty-box .input-group .btn:active {
  border: none;
  box-shadow: none;
  outline: 0;
}

.Flight-ctm-icon.rotate {
  transform: rotatex(180deg);
  transition: 0.2s;
}

.Flight-ctm-icon {
  transition: 0.2s;
}

.Flight-pax-num {
  width: 30px;
  text-align: center;
  font-weight: 700;
  color: #848a8d;
}

.Flight-pax-calc {
  background-color: #f2f2f3;
  border: 1px solid #e4e4e6;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.Flight-val-plus {
  background-color: #4B2269;
  border-color: #4B2269;
}

.Flight-fliStopsDisc {
  width: 5px;
  height: 5px;
  display: inline-block;
  background-color: #93989a;
  position: relative;
  z-index: 2;
  margin: 0 5px;
  border-radius: 20px;
  top: -8px;
}

.radio_animated {
  cursor: pointer;
  margin-left: -1.25rem;
  position: relative;
  margin-right: 5px;
  margin-top: 0.35em !important;
}

.radio_animated:checked:before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.radio_animated:before {
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75),
    -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
  content: "";
  position: absolute;
  top: 3px;
  left: 2px;
  z-index: 1;
  width: 7px;
  height: 7px;
  background: #4B2269;
  border-radius: 50%;
}

.radio_animated:after {
  content: "";
  position: absolute;
  top: -1px;
  left: -0.125rem;
  width: 15px;
  height: 15px;
  background: #fff;
  border: 1px solid #93989a;
  border-radius: 50%;
}

.form-check-input:checked,
.form-check-input[type="radio"] {
  border-color: transparent !important;
  background-color: transparent !important;
}

.Flight-widgetSection .form-group .form-control {
  border: 1px solid #d9d9d9;
  padding: 15px 12px 15px 40px;
  border-radius: 6px;
  color: #333;
  background-color: #fff;
  height: 51px;
  width: 100%;
  padding-right: 26px !important;
  text-overflow: ellipsis;
}



.Flight-widgetSection .Flight-search-block,
.Flight-widgetSection .Flight-date-block {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  background-color: #fff;
}

.Flight-widgetSection .Flight-date-block .form-group {
  flex: 1 0 auto;
}

input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
  transition: background-color 5000s ease-in-out;
}


.bg-white,
.breadcrumb,
.Flight-detail-flight .Flight-detail-wrap .accordion .accordion-header .accordion-button,
.Flight-detail-flight .Flight-detail-wrap .accordion .accordion-item,
.Flight-detail-flight .flight-fd-inner,
.flight-detail-sec .Flight-detail-bar .Flight-detail-wrap .Flight-airport-part .Flight-airport-progress .Flight-flistop-points:after,
.flight-detail-sec .Flight-detail-bar .Flight-detail-wrap .Flight-airport-part .Flight-airport-progress .Flight-flistop-points:before,
.more-menu-op,
.or>div {
  background-color: #fff;
}

.iti__flag-container:after {
  content: "";
  width: 1px;
  height: 22px;
  background-color: #d9d9d9;
  display: block;
  position: absolute;
  top: 8px;
  left: 50px;
  z-index: 9;
}

.iti input::placeholder {
  font-size: 12px;
  color: #a6a6a8;
}

.iti input:-ms-input-placeholder {
  font-size: 12px;
  color: #a6a6a8;
}

.iti input::-ms-input-placeholder {
  font-size: 12px;
  color: #a6a6a8;
}

::placeholder {
  font-size: 14px;
  color: #a6a6a8;
}

:-ms-input-placeholder {
  font-size: 12px;
  color: #a6a6a8;
}

::-ms-input-placeholder {
  font-size: 12px;
  color: #a6a6a8;
}

.iti--allow-dropdown input[type="tel"],
.iti--separate-dial-code input[type="tel"] {
  padding-left: 60px;
}

.Flight-form-control {
  border-radius: 6px;
}

.Flight-custom-checkbox .Flight-custom-control-input:checked~.Flight-custom-control-label::before {
  background-color: #6900a0;
  border-color: #6900a0;
  box-shadow: none;
}

.bg-transparent {
  background: #32393d;
  border: 1px solid #fff;
}

.modal-bg-transparent {
  background-color: transparent;
  border: none;
}

.Flight-search-result {
  background-color: #fff;
  border-radius: 6px;
  margin-bottom: 15px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border: 1px solid #ddd;
}
/* .Flight-search-result:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .2);
    -webkit-transition: 0.3s; border-color:transparent;
  transition: 0.3s;
} */

.Flight-logo-sec img {
  width: 36px;
  height: auto;
}

.Flight-logo-sec .title {
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  line-height: 18px;
}

.Flight-airport-part .Flight-airport-name h4 {
  margin-bottom: 6px;
  line-height: 1.2;
}

.Flight-book-flight h3,
.Flight-ln {
  line-height: 1.2;
}

.Flight-airport-part .Flight-airport-name h6 {
  margin-bottom: -5px;
  line-height: 1.2;
}

.Flight-airport-part .Flight-airport-progress {
  width: 128px;
  margin: 0 20px;
  position: relative;
}

.Flight-airport-part .Flight-airport-progress .stop {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -7px;
  width: 100%;
}

.bookFlightDetail {
  border-top: 2px dashed #e4e4e4;
  padding-top: 15px;
  padding-bottom: 15px;
}

.Flight-viewdetail-arrow:after {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -3px;
  border: solid #6900a0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transition: transform 350ms, -webkit-transform 350ms;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 5px;
}

.Flight-viewdetail-arrow.collapsed:after {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.Flight-sorting.sorted img.rotate180,
.Flight-layover-time:before {
  transform: rotate(180deg);
}

.Flight-regulartype {
  background-color: #f8cf46;
  font-weight: 600;
}

.Flight-btn-detail li {
  border-right: 1px solid #d9d9d9;
  padding-right: 12px;
  margin-right: 12px;
}

.Flight-bgg-info:after {
  content: "";
  background-color: #8b8d90;
  width: 5px;
  height: 5px;
  border-radius: 10px;
  margin: 0 5px;
}

button.Flight-btn-purple {
  background-color:#4B2269;
  /* background: linear-gradient(0deg,#7806B2,#CB89ED); */
  border:none !important;
  color: #fff !important; 
  font-size:14px;
  font-weight: 600;
  position: relative;
  transition:0.3s ease-in-out;
  z-index: 0; overflow:hidden;
}
button.Flight-btn-purple:hover, button.Flight-btn-purple:active{background-color:#632D8B !important}


button.Flight-btn-purple-outline {
  border: 1px solid #dcdcdc;
  color: #273135;
}

button.Flight-btn-purple-outline:hover {
  background-color: #6900a0;
}

.Flight-detail-wrap {
  border-top: 2px dashed #eaeaea;
}

.Flight-detail-wrap .Flight-nav-tabs .nav-item.show .nav-link,
.Flight-detail-wrap .Flight-nav-tabs .nav-link.active {
  color:#6900a0!important;
  border-radius: 8px;
  border: none;
  background-color:#e8def8;
}

.Flight-detail-wrap .Flight-nav-tabs .nav-link,
.Flight-detail-wrap .Flight-nav-tabs .nav-link:focus,
.Flight-detail-wrap .Flight-nav-tabs .nav-link:hover {
  border: none;
  color: #273135;
  font-weight: 600;
  font-size: 14px;
}

.Flight-detail-wrap .nav-link {
  padding: 0.5rem 1rem;
  border-radius: 8px;
}

.flight-info-wrap {
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 15px;
}

.flight-info-wrap .flight-info {
  border-bottom: 1px dashed #d9d9d9;
  position: relative;
  padding: 20px;
  background: #fff;
  border-radius: 16px;
}

.Flight-faretype .pax-detail:last-child,
.Flight-fb-table tr:last-child td,
.Flight-rt-int-flights .form-check:last-child .form-check-label,
.flight-info-wrap .flight-info:last-child {
  border-bottom: none;
}

.flight-info-wrap .flight-info:after,
.flight-info-wrap .flight-info:before {
  content: "";
  display: block;
  width: 11px;
  height: 20px;
  background-repeat: no-repeat;
  position: absolute;
  bottom: -10px;
}

.flight-info-wrap .flight-info:before {
  background-image: url("../../assets/images/home/search-result/left-arrow.svg");
  left: -3px;
}

.flight-info-wrap .flight-info:after {
  background-image: url("../../assets/images/home/search-result/right-arrow.svg");
  right: -3px;
}

.Flight-layover-time {
  left: 0;
  right: 0;
  bottom: -7px;
  z-index: 1;
}

.Flight-layover-time span {
  background-color: #e8def8;
  letter-spacing: 0.6px;
}

.Flight-sorting-mobile.show,
.filter-mobile.show {
  left: 0;
  overflow-y: auto;
}

.ref-tag {
  background-color: #44ac23;
}

.Flight-fb-table tr td {
  border-right: 1px solid #dee2e6;
  box-shadow: none;
}
.Flight-fb-table tr td:last-child{border-right:none}
.Flight-box-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  display: grid;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.Flight-stops-slot {
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: var(--grid-row-gap);
}

.Flight-inner-detail {
  grid-row-gap: 0;
  grid-column-gap: 10px;
}

.Flight-timeblock {
  line-height: 14px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
}

.Flight-filter-txt {
  color: rgba(25, 29, 35, 0.56);
  font-weight: 500;
  font-size: 14px;
}

.Flight-w-icon {
  height: 30px;
}

.Flight-filterouter {
  margin-top: 20px;
}

.Flight-filter-al-img {
  width: 18px;
  margin-right: 8px;
}

.Flight-search-inner {
  padding: 20px 20px 0;
}

.Flight-filter-selected,
.Flight-stopp.Flight-filter-selected {
  background-color: #e8def8;
  color: #6900a0;
  border-color: #e8def8 !important;
}

.Flight-timeblock.filterslot .Flight-stopp.Flight-filter-selected:first-child {
  border-radius: 5px 0 0 5px;
}

.Flight-timeblock.filterslot .Flight-stopp.Flight-filter-selected:last-child {
  border-radius: 0 5px 5px 0;
}

.mobile-filter {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  padding: 10px 15px;
  display: none;
}

.Flight-left-sidebar,
.Flight-left-sidebar2 {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #dcdcdc;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.Flight-left-sidebar2 {
  background-color: #f9f9f9;
  border: 1px solid rgba(223, 226, 223, 0.45);
}

.Flight-left-sidebar .Flight-back-btn {
  padding: 10px;
  border-bottom: 1px solid #fafafa;
  display: none;
  font-size: 14px;
  color: #000;
  font-weight: 700;
  margin-bottom: 0;
  cursor: pointer;
  background-color: #fff;
  text-align: left;
}

.Fligh-gray-img,
.accordion-button:not(.collapsed)::after {
  filter: grayscale(100%);
}

.op-2 {
  opacity: 0.3;
}

.Flight-inner-detail {
  grid-template-columns: 1.5fr 3fr 1fr;
}

.Flight-shadow-box {
  border: 1px solid #dcdcdc;
  border-radius: 5px;
}

.Flight-static-div {
  position: -webkit-sticky;
  position: sticky;
  top:20px;
}

.Flight-addon-wrap .Flight-addon-border {
  border-bottom: 1px solid #dcdcdc;
}

.Flight-addon-wrap>div:last-child {
  margin-bottom: 0;
  border-bottom: none !important;
}

.Flight-addon-scroll {
  max-height: 270px;
  overflow-y: auto;
  padding-left: 0;
}

.Flight-trav-list tr td,
.Flight-trav-list tr th {
  font-weight: 500;
  width: 33.33%;
  text-align: center;
  padding-bottom: 2px;
  padding-top: 0;
}

.Flight-fliStopsSepLine.Flight-flistop-points {
  width: auto;
  height: auto;
  left: 0;
  right: 0;
  top: 5px;
  border-top: 1px solid #93989a;
  margin: auto;
  position: absolute;
  bottom: 0;
  z-index: 1;
}

.Flight-flistop-points:after {
  right: 0;
}

.Flight-flistop-points:before {
  left: 0;
}

.Flight-flistop-points:after,
.Flight-flistop-points:before {
  width: 5px;
  height: 5px;
  background-color: #93989a;
  border-radius: 50%;
  content: "";
  display: inline-block;
  position: absolute;
  top: -3px;
}

.Flight-custom-control-label::after,
.Flight-custom-control-label::before {
  top: 0.25rem;
  height: 1rem;
  width: 1rem;
  display: block;
  position: absolute;
}

.Flight-trav-list tr td:first-child,
.Flight-trav-list tr th:first-child {
  text-align: left;
}

.Flight-trav-list tr td:last-child,
.Flight-trav-list tr th:last-child {
  text-align: right;
}

.payment-method .form-check {
  border-bottom: 1px solid #e4e4e4;
  padding: 10px 32px;
}

* [class^="icon-"] {
  background: url("../../assets/images/home/sprite-img.svg") no-repeat;
  display: inline-block;
}

.react-datepicker-popper {
  width: 400px;
  z-index: 9999 !important;
}

.Flight-datepicker .react-datepicker {
  width: 100%;
  border: none !important;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  font-size: 13px;
}

.Flight-datepicker .react-datepicker__current-month {
  font-size: 13px;
}

.Flight-datepicker .react-datepicker__month-container {
  float: none !important;
}

.Flight-datepicker .react-datepicker__day-names,
.Flight-datepicker .react-datepicker__week {
  display: flex;
  justify-content: space-around;
  margin-bottom: 0;
}

.Flight-datepicker .react-datepicker__day,
.Flight-datepicker .react-datepicker__day-name,
.Flight-datepicker .react-datepicker__time-name {
  width: 100% !important;
}

.Flight-datepicker .react-datepicker__week {
  border-top: 1px solid #e6e6e6;
}

.Flight-datepicker .react-datepicker__header {
  border-bottom: none !important;
  padding-bottom: 0 !important;
  background-color: transparent !important;
}

.Flight-datepicker .react-datepicker__day {
  height: 50px;
  border-left: 1px solid #e6e6e6;
  border-radius: 0 !important;
  margin: 0 !important;
  line-height: inherit !important;
  display: inline-flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Flight-datepicker .react-datepicker__day p {
  margin-bottom: 0;
  color: #6900a0;
  font-size: 10px;
  font-weight: 700;
}

.Flight-datepicker .react-datepicker__day--selected {
  background-color: #e8def8 !important; color:#6900a0; font-weight:600;
}

.Flight-datepicker .react-datepicker__day--selected p,
.Flight-datepicker .react-datepicker__day--selected:hover p,
.Flight-datepicker .react-datepicker__day:hover p {
  color: #fff;
}

.Flight-datepicker .react-datepicker__day--selected:hover,
.Flight-datepicker .react-datepicker__day:hover {
  border-radius: 0 !important;
  background-color: #e8def8 !important; color:#6900a0;
}

.Flight-datepicker .react-datepicker__day span {
  display: block;
  padding-top: 5px;
  font-weight: 700;
  font-size: 14px;
}

.Flight-datepicker .react-datepicker__week .react-datepicker__day:last-child {
  border-right: 1px solid #e6e6e6;
}

.Flight-datepicker .react-datepicker__month .react-datepicker__week:last-child .react-datepicker__day {
  border-bottom: 1px solid #e6e6e6;
}

.Flight-rt-int-flights .form-check:first-child,
.Flight-datepicker .react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 0;
}

.Flight-datepicker .react-datepicker__navigation {
  top: 5px;
}

.dropdown-item.active,
.dropdown-item:active,
.react-datepicker__close-icon::after {
  background-color: #6900a0 !important;
  font-size: initial !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}



.Flight-home-heading {
  font-size: 48px;
  font-weight: 600;
}

.Flight-home-para {
  font-size: 24px;
  line-height: 32px;
  color: #696969;
}

.Flight-datepicker .react-datepicker__day--keyboard-selected {
  background-color:#e8def8 !important;  color: #6900a0;
}

.Flight-custom-select .form-select {
  height: 52px;
  font-size: 13px;
}

select.form-select:focus {
  box-shadow: none;
  border-color: #d9d9d9;
}

.Flight-temp sup {
  font-size: 9px;
  font-weight: 700;
  line-height: 18px;
  top: -6px;
}

.Flight-temp {
  background-color: #fff;
  font-weight: 500;
  color: #273135;
  font-size: 14px;
  z-index: 1;
  position: relative;
  display: inline-block;
}

.Flight-yellow-gradient {
  background-color: #fafafa;
  border-bottom: 1px solid #dcdcdc;
  transition: position 10s;
}

.Flight-result-widget {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}

.Flight-detail-wrap .accordion .accordion-item,
.Flight-selection-bar,
.Flight-totalfr,
.flight-fd-inner {
  background-color: #f9f9f9;
}

.Flight-result-widget .Flight-selector-box-flight {
  width: 200%;
}

.sort-filter {
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  background-color: #fff;
  padding: 10px 15px;
}

.Flight-sticky_nav {
  width: 100%;
  top: 0;
  display: block;
  position: sticky;
  z-index:9;
  transition: 0.5s;
  left: 0;
}

.Flight-sorting {
  padding: 0;
  display: inline-block;
}

.Flight-sorting img {
  visibility: hidden;
}

.Flight-sorting.sorted img {
  display: inline-block;
  visibility: visible;
  margin-left: 4px;
}

.Flight-sorting.sorted img.rotate0 {
  transform: rotate(0);
}

.Flight-filterdp {
  padding-bottom: 8px;
}

.Flight-custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.Flight-custom-control-input {
  position: absolute;
  left: 0;
  z-index: 1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.Flight-custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  font-weight: 600;
}

.Flight-custom-control-label::before {
  left: -1.5rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}

.Flight-custom-checkbox .Flight-custom-control-label::before {
  border-radius: 0.25rem;
}

.Flight-custom-control-label::before,
.Flight-custom-select,
.custom-file-label {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.Flight-custom-control-label::after {
  left: -1.5rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.Flight-custom-checkbox .Flight-custom-control-input:checked~.Flight-custom-control-label::after {
    background-image: url("/src/assets/images/home/checkmark.svg");
}

.flight-fd-inner {
  border: 1px solid rgba(223, 226, 223, 0.48);
  border-radius: 6px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

button.accordion-button:focus {
  outline: 0;
  border: none;
  box-shadow: none;
}

button.accordion-button:not(.collapsed) {
  background-color: transparent;
  color: #000;
  box-shadow: none;
}

button.accordion-button {
  font-size: 14px;
  font-weight: 600;
  padding: 12px;
  border-radius: 6px;
}

.accordion-item {
  margin-bottom: 5px;
  border-radius: 6px;
}

.accordion-item:not(:first-of-type) {
  border-top: 1px solid #dee2e6 !important;
}

.Flight-fd-table .table tr:last-child td {
  width: 50%;
  background-color: #f4f4f4;
  border-bottom: none;
}
.Flight-fd-table .table tr td{border-bottom:1px solid #ddd;}

.Flight-total-fare {
  border-top: 2px dotted #c9c9c9;
}

.form-control.form-home {
  font-size: 14px;
  height: 42px;
}

.form-control.form-home:focus,
.Flight-telpicker .react-tel-input.c-code-control .form-control:focus {
  border: 1px solid #cacaca !important;
  box-shadow: none !important;
}

.Flight-border-dashed {
  position: relative;
  border-top: 2px dashed #e4e4e4;
  border-bottom: 2px dashed #e4e4e4 !important;
}
.Flight-table-div table tr{position:relative;}
.Flight-table-div table tr td:first-child {
  font-weight:600;
  font-size:14px;
  padding:0;
  max-height:22px;}


button.Flight-sm-btn {
  min-width: 80px;
}

.Flight-roundtrip-result .Flight-logo-sec img {
  width: 30px;
}

.flight-detail-sec.book_panel {
  display: flex;
  align-items: center;
  height: 80px;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 2;
  background-color: #273135;
  -webkit-box-shadow: -1px -1px 5px 0 rgba(77, 77, 77, 0.14);
  box-shadow: -1px -1px 5px 0 rgba(77, 77, 77, 0.14);
  margin: 0;
}

.flight-detail-sec .Flight-detail-bar .Flight-detail-wrap {
  color: #fff;
  border-radius: 5px;
  padding: 15px 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.Flight-oneway .arr-div,
.Flight-oneway .dep-div,
.Flight-oneway .dur-div,
.Flight-twoway .arr-div,
.Flight-twoway .dep-div,
.Flight-twoway .dur-div,
.flight-detail-sec .Flight-detail-bar .Flight-detail-wrap .Flight-logo-sec {
  text-align: center;
}

.flight-detail-sec.book_panel .Flight-detail-bar .Flight-detail-wrap .Flight-logo-sec img {
  width: 35px;
  margin-bottom: 0;
}

.flight-detail-sec .Flight-detail-bar .Flight-detail-wrap .Flight-airport-part {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  justify-content: space-between;
}

.flight-detail-sec .Flight-detail-bar .Flight-detail-wrap .Flight-airport-part .Flight-airport-progress {
  width: 30%;
  padding: 0 10px;
  margin: 0;
  position: relative;
}

.flight-detail-sec .Flight-detail-bar .Flight-detail-wrap .Flight-airport-part .Flight-airport-progress .Flight-fliStopsSep {
  height: 17px;
}

.flight-detail-sec .Flight-detail-bar .Flight-detail-wrap .Flight-airport-part .Flight-airport-name h4 {
  margin-bottom: 3px;
  margin-top: -4px;
  font-size: 14px;
  font-weight: 600;
}

.flight-detail-sec .Flight-detail-bar .Flight-detail-wrap .Flight-airport-part .Flight-airport-name h6 {
  margin-bottom: -5px;
  color: #fff;
  font-size: 14px;
}

.flight-detail-sec .Flight-detail-bar .Flight-detail-wrap .Flight-price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  text-align: center;
}

.flight-detail-sec .Flight-detail-bar .Flight-detail-wrap .Flight-price h4 {
  margin-bottom: -4px;
  font-weight: 700;
  font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
}

.flight-detail-sec.book_panel .Flight-detail-bar.border-cls .Flight-detail-wrap .row {
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
}

.flight-detail-sec .Flight-detail-bar .Flight-detail-wrap .Flight-airport-part .Flight-airport-progress .Flight-fliStopsSepLine.Flight-flistop-points {
  border-color: #fff;
}

.Flight-roundtrip-result .Flight-search-result.selected {
  background-color:rgba(0,0,0,0.05);
}

.Flight-roundtrip-result .Flight-fnum {
  display: block;
  margin-left: 0 !important;
}

.Flight-roundtrip-result .bookFlightDetail .Flight-btn-detail li:first-child {
  display: block !important;
  border-right: none;
}

.Flight-roundtrip-result .Flight-search-inner {
  padding: 20px 12px 0;
}

.Flight-roundtrip-result .Flight-inner-detail {
  grid-template-columns: 1.5fr 3fr;
}

.Flight-roundtrip-result .flight-info-wrap .flight-info {
  padding: 15px 10px 20px;
}

.Flight-roundtrip-result .rt-cncl {
  width: 100% !important;
}

.Flight-selection-bar {
  padding: 10px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
  border: 1px solid rgba(223, 226, 223, 0.45);
  border-radius: 6px;
  justify-content: space-between;
}

.Flight-selection-bar span {
  font-size: 14px;
  font-weight: 600;
}

.addon-count button.btn {
  background-color: transparent;
  min-width: auto;
  font-size: 22px;
  padding: 0;
  width: 26px;
  height: 32px;
  color: #848a8d;
}

.Flight-faretype {
  border-top: 1px solid #d9d9d9;
}

.Flight-rt-int-flights {
  border: 1px solid #dcdcdc;
  border-radius: 6px;
}

.Flight-rt-int-flights .form-check-label {
  border-bottom: 2px dashed #eaeaea;
  padding-bottom: 10px;
}

.Flight-rt-int-flights .form-check {
  padding-top: 5px;
}

.price-range-bg {
  background-image: url("../../assets/images/home/price-range-bg.png");
  background-size: cover;
  height: 46px;
}

.book_panel .Flight-detail-bar {
  border-right: 1px solid #fff;
  padding-right: 15px;
  margin: 10px 0;
}

.grand-total {
  padding-left: 15px;
}

.book_panel.flight-detail-sec .Flight-detail-bar .Flight-detail-wrap {
  padding: 0;
}

.Flight-custom-select .Flight-fares {
  background: #f9f9f9;
}

.Flight-layover-time:after,
.Flight-layover-time:before {
  background-image: url("../../assets/images/home/corner.svg");
  width: 11px;
  height: 24px;
  position: absolute;
  top: -3px;
  content: "";
  display: block;
}

.book_panel .Flight-box-stretch {
  grid-template-columns: 2fr 2fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.Flight-select-fare .form-check-label {
  font-size: 14px;
  font-weight: 500;
}

.Flight-reqClass .flight-info {
  padding: 15px 15px 20px !important;
  border-bottom: 2px dashed #e4e4e4 !important;
  border-radius: 16px 16px 0 0;
}

.Flight-layover-time:after {
  left: -1px;
}

.Flight-layover-time:before {
  right: -1px;
}

.Flight-reqClass:last-child .flight-info {
  border-bottom: none !important;
  border-radius: 16px;
}

.booking-msg {
  background-color: #f6e9ff;
  border-radius: 10px;
}

.pmt-msg {
  padding-top: 1.5rem !important;
}

.dshd-sprtr {
  position: relative;
  background-image: linear-gradient(to right,
      rgba(0, 0, 0, 0.18) 50%,
      rgba(0, 0, 0, 0) 0%);
  background-position: bottom;
  background-size: 10px 1px;
  background-repeat: repeat-x;
  height: 1px;
}



.fw-semi-bold {
  font-weight: 500;
}

.Flight-ctm-slct .select__single-value {
  font-size: 13px;
  width: 100%;
}


.swap-icn.swap-city {
  transform: translate(-50%, -20%) rotate(90deg) !important;
  transition: 0.5s !important;
}

.Flight-ctm-slct .select__control .select__dropdown-indicator,
.Flight-ctm-slct .select__control .select__indicator-separator,
.Flight-home-main-container .Flight-sticky_nav,
.Flight-home-main-container .edit-search,
.Flight-int .arr-div,
.Flight-int .dep-div,
.Flight-int .dur-div,
.Flight-return-filter .Flight-home-padding .Flight-filterouter:first-child,
.Flight-rt-mob-show,

.dob-dd .react-datepicker__current-month {
  display: none;
}

.Flight-ctm-slct .select__control .select__indicator {
  padding: 0;
  width: 16px;
}

.Flight-ctm-slct .select__menu {
  z-index: 2;
}

.Flight-ctm-slct .select__control {
  padding: 7px 5px 8px 40px;
  border-radius: 6px;
  color: #333;
  background-color: #fff;
  height: 51px;
  width: 100%;
  cursor: pointer;
}

.Flight-ctm-slct .select__control .select__menu {
  font-size: 13px;
}

.Flight-ctm-slct .select__control:focus, .Flight-ctm-slct .select__control:hover {
  border: 1px solid #ccc;
  box-shadow: none;
}

.Flight-datepicker .react-datepicker__navigation-icon {
  top: 5px !important;
}

.dob-dd .react-datepicker__month-dropdown-container--select,
.dob-dd .react-datepicker__year-dropdown-container--select {
  margin: 0;
}

.dob-dd .react-datepicker__month-dropdown-container--select select,
.dob-dd .react-datepicker__year-dropdown-container--select select {
  width: 100px;
  margin: 0;
  padding: 5px 10px;
  font-weight: 500;
  background: url("../../assets/images/home/dd-arrow.svg") right 0.35rem center/16px 12px no-repeat;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

.dob-dd .react-datepicker__month-dropdown-container--select select {
  border-radius: 5px 0 0 5px;
}

.dob-dd .react-datepicker__year-dropdown-container--select select {
  border-radius: 0 5px 5px 0;
  border-left: none;
}

.dob-dd .react-datepicker__month-dropdown-container--select select:focus,
.react-datepicker__year-dropdown-container--select select:focus {
  outline: 0;
}

.box-shadow-none {
  box-shadow: none;
}

.footer-bg {
  background: #fef1c7;
  background: linear-gradient(0deg, #fef1c7 0, #fefefb 100%);
}

#root,
body,
html {
  height: 100%;
}

.Flight-wrapper {
  /* padding-top: 80px; */
  min-height: 100%;
  height: auto !important;
  margin: 0 auto -225px;
}

.push,
footer.footer-bg {
  height: 225px;
}

.Flight-ctm-slct {
  cursor: pointer;
}

.Flight-depart-slct.Flight-ctm-slct .select__control .select__indicator {
  margin-right: 15px;
}

.Flight-depart-slct.Flight-ctm-slct .select__control {
  padding-left: 30px;
}

.Flight-addon-tabs .nav-tabs {
  border-bottom: 1px solid #dee2e6;
  padding-left: 20px;
}

.Flight-addon-tabs .tab-content {
  padding: 0 0 10px;
}

.Flight-addon-tabs .tab-content .tab-content {
  border: none;
  padding: 0;
}

.Flight-addon-tabs .nav-tabs .nav-link {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  border: 1px solid #dee2e6;
  margin-right: 10px;
}

.Flight-addon-tabs .nav-tabs .nav-link.active {
  color: #6900a0 !important;
  background-color: transparent !important;
  border-bottom-color: #fff !important;
}

.Flight-addon-tabs .tab-content .nav-tabs .nav-link {
  border: none;
  border-bottom: 2px solid transparent !important;
}

.Flight-addon-tabs .tab-content .nav-tabs .nav-link.active {
  border-bottom-color: #6900a0 !important;
}

.Flight-addon-tabs .tab-content .nav-tabs {
  margin-left: 0;
  margin-bottom: 10px;
}

.Flight-addon-tabs .nav-tabs .nav-item,
.Flight-filterouter .multi-range-slider .labels,
.Flight-selector-box-flight .form-check:last-child {
  margin-bottom: 0;
}

.Flight-mob-detail .bookFlightDetail {
  border-top: none;
  padding-top: 0;
  background-image: none;
}

.Flight-home-graphic {
  position: absolute;
  right: 0;
  opacity: 0.5;
  top: 0;
}

.Flight-wrapper header {
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 12%);
  position: relative;
  z-index: 10;
}

.Flight-loaderGIF.search-loader {
  height: calc(100vh - 470px);
}

.Flight-loaderGIF.search-loader1 {
  height: calc(100vh - 320px);
}

.before-seprator-flt-dtl:after,
.before-seprator-flt-dtl:before {
  content: "";
  width: 7px;
  height: 7px;
  background: #1d1b1f;
  left: -4px;
  border-radius: 50%;
}

.select__menu {
  width: 330px !important;
  left: 0 !important;
}

.Flight-ticket-main {
  box-shadow: 0px 0px 10px 0px rgba(105,105,105,0.16);
    border: 1px solid #E7E0EC;
  border-radius: 10px;
}

.Flight-lght-txt {
  color: rgba(39, 49, 53, 0.7);
  font-size: 14px;
  font-weight: 500;
}

.Flight-drk-txt {
  color: #1d1b1f;
  font-size: 15px;
  font-weight: 500;
}

.Flight-ticket-bg {
  position: absolute;
  z-index: 0;
  left: 0;
  right: 0;
  text-align: center;
  top: 50%;
  margin-top: -57px;
  opacity: 0.4;
}

.select__menu .select__option.select__option--is-selected {
  background-color: #e8def8 !important;
  color: #000;
}

.Flight-itinerary-main {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px 1.5px #0000003b;
}

.before-seprator-flt-dtl {
  border-left: 2px dashed #606060;
  margin: 0 25px;
  text-align: center;
  height: auto;
  position: relative;
}

.before-seprator-flt-dtl:after {
  position: absolute;
  top: 0;
}

.before-seprator-flt-dtl:before {
  position: absolute;
  bottom: 0;
}




.Flight-roundtrip-result .Flight-search-inner .Flight-detail-wrap .nav-tabs .nav-link {
  font-size: 12px;
  line-height: 16px;
  padding: 0.5rem;
}

.Flight-ssr-icon {
  min-width: 22px;
}

.Flight-cal-icon {
  position: absolute;
  top: 12px;
  right: 10px;
}

.Flight-fb-table tr:first-child td,
.Flight-log-search .form-control:focus {
  background-color: #f4f4f4;
}


.Flight-widgetSection form .Flight-roundtrip .form-m {
  margin-bottom: 20px;
}

.Flight-faretype .pax-detail {
  border-bottom: 1px solid rgba(204, 204, 204, 0.43);
}

.Flight-sticky-searchbar {
  position: sticky;
  left: 0;
  top: -10px;
  z-index: 999;
}

.Flight-vm-table {
  border: 1px solid #dee2e6;
  padding: 0 10px 10px;
}

.Flight-vm-table table tr th {
  text-transform: uppercase;
  color: #7f7f7f;
  padding-top: 15px;
  padding-bottom: 15px;
  height: 49px;
  border: 0;
  vertical-align: top;
}

.Flight-log-search {
  position: relative;
}

.Flight-log-search img {
  position: absolute;
  right: 10px;
  top: 10px; cursor:pointer;
  border-left: 1px solid #D9D9D9;
  padding-left: 8px;
}



.Flight-cal-icon2 {
  position: absolute;
  top: 15px;
  left: 10px;
}

.Flight-datesearch-log .form-control {
  padding-left: 35px;
  padding-right: 35px;
  height: 44px;
}

.Flight-datesearch-log .Flight-pax-arrow img {
  right: 10px !important;
  top: 20px !important;
}

.Flight-datesearch-log .form-control:focus {
  border-color: #ced4da;
}


.Flight-baggage-table tr td:first-child {
  width: 40%;
}

.Flight-aply-fltr-btn {
  position: sticky;
  bottom: 0;
  z-index: 1;
  font-size: 16px !important;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.Flight-box-stretch.Flight-sf-tabs {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}

.Flight-sf-tabs.nav-tabs .nav-link {
  width: 100%;
  color: #333;
  font-weight: 500;
  font-size: 14px;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid transparent;
}

.Flight-sf-tabs.nav-tabs .nav-link.active {
  border-bottom-color: #6900a0;
  color: #6900a0;
  background-color: transparent !important;
}

.Flight-home-padding .Flight-filterouter:first-child {
  margin-top: 0;
}

.Flight-fares label,
.Flight-roundtrip-result .Flight-airport-part .Flight-airport-name h6 span {
  font-size: 12px;
}

.Flight-home-main-container .home-search-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.Flight-home-main-container .home-search-btn button.Flight-search-flight-btn {
  margin: 0 auto;
}

.Flight-inner-page-search .Flight-sticky-searchbar.show-searchbar {
  top: 0;
  height: 100%;
}

.Flight-filterouter .multi-range-slider {
  border: none;
  box-shadow: none;
  padding: 0; z-index:0;
}

.Flight-filterouter .multi-range-slider .bar-inner {
  background-color: #6900a0;
  border: none;
  box-shadow: none;
}

.Flight-filterouter .multi-range-slider .thumb::before {
  border: 2px solid #6900a0;
  box-shadow: none;
  width: 15px;
  height: 15px;
  margin-top: -6px; z-index:0;
}

.Flight-filterouter .multi-range-slider .bar-left,
.Flight-filterouter .multi-range-slider .bar-right {
  padding: 2px 0;
  box-shadow: none;
}

.Flight-filterouter .multi-range-slider .thumb .caption * {
  background-color:#6900a0; 
  box-shadow: none;
  line-height: 20px;
  height: 20px;
}

.Flight-filterouter .multi-range-slider .thumb-left.thumb::before {
  margin-left: 0;
}

.Flight-cstcal {
  right: 10px;
  top: 8px;
}

.Flight-form-bck {
  position: absolute;
  top: -18px;
  left: 10px;
  rotate: 180deg;
}

.Flight-note {
  padding: 0 !important;
  background-color: #f0f0f0 !important;
}

.Flight-note div {
  background-color: #f0f0f0;
  padding: 10px;
}

.Flight-cstm-table table tr:nth-child(2n-1) td {
  border: 0 !important;
}

.Flight-IATA-code,
.Flight-airport-name h4,
.Flight-airport-name h6,
.Flight-airport-part .Flight-airport-progress .stop {
  font-weight: 600;
}

.Flight-rtab .tab-content {
  padding-left: 12px;
  padding-right: 12px;
}

.modal.Flight-modal {
  z-index: 9999 !important;
}

.ssrSwitchLable {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
}

.ssrSwitchLable input {
  opacity: 0;
  width: 0;
  height: 0;
}

.ssrSwitch {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.ssrSwitch:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.ssrSwitch {
  background-color: #6900a0;
}

input:focus+.ssrSwitch {
  box-shadow: 0 0 1px #6900a0;
}

input:checked+.ssrSwitch:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}

.ssr-dd .select__control {
  border-color: #dee2e6;
}

.ssr-dd .select__control--is-focused,
.ssr-dd .select__control--is-focused:hover {
  border-color: #dee2e6 !important;
  box-shadow: none;
}

.ssr-dd .select__control--is-focused {
  border-color: #dee2e6 !important;
}

.Flight-ctm-slct .select__option--is-focused {
  background-color: rgba(0, 0, 0, 0.06);
}

.cancel-booking-block {
  border: 1px solid #d9d9d9;
}

.b-dot:after {
  content: "";
  width: 5px;
  height: 5px;
  background-color: #707070;
  border-radius: 5px;
  display: inline-block;
  margin-left: 4px;
  margin-bottom: 2px;
}

.Flight-line-seperation {
  display: inline-block;
  width: 2px;
  height: 20px;
  background-color: #93989a;
  margin-top: 3px;
}

.Flight-inner-detail2 {
  grid-template-columns: 2fr 5fr 1fr;
}

.absolute-close .btn-close {
  position: absolute;
  right: 12px;
}

.Flight-mainContainer nav ul {
  margin: 0;
  padding: 20px 0;
}

.Flight-mainContainer nav ul li a {
  display: block;
  text-decoration: none;
  color: #000;
  font-size: 15px;
  position: relative;
  padding: 9px 15px;
  transition: 0.5s;
  font-weight: 500;
  border-radius: 8px;
}

.Flight-mainContainer nav ul li.active a {
  background-color: #f7ddd0;
}

.Flight-save-trav-list {
  min-width: 600px;
  overflow-x: auto;
}
.Flight-save-trav-list tr th, .Flight-save-trav-list tr td{background-color:transparent;}

.ap-part2 {
  grid-template-columns: 2fr 1fr 2fr 1.5fr;
  align-items: baseline;
}

.Flight-modal .modal-header .btn-close {
  background: url("../../assets/images/home/close-icon.svg") !important;
  opacity: 1;
  padding: 0 !important;
  margin: 0 !important;
  width: 15px;
  height: 15px;
}

.Flight-vm-table3 table tr td {
  vertical-align: top;
}

.Flight-telpicker .react-tel-input .country-list .country {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.Flight-telpicker .react-tel-input .country-list .flag {
  top: 2px !important;
}

.Flight-telpicker .react-tel-input.c-code-control .form-control {
  height: 42px;
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
  background-color: transparent !important; border-color:#dee2e6;
}

.Flight-telpicker .react-tel-input.c-code-control .flag-dropdown {
  background-color: transparent !important;
  border: none !important;
}

.Flight-telpicker .react-tel-input.c-code-control .selected-flag.open:before,
.Flight-telpicker .react-tel-input.c-code-control .selected-flag:focus:before {
  box-shadow: none;
  border-color: transparent;
}

.Flight-hide-popular-filter {
  display: block;
  position: fixed;
  max-width: 23%;
  min-width: 318px;
  background: #fff;
  top: 117px;
  padding: 5px 15px 15px;
  opacity: 1;
  border: 1px solid #d9d9d9;
  border-radius: 0 0 5px 5px;
  margin-left: -11px;
  border-top: none;
  z-index: 9;
}

.Flight-detail-wrap .accordion .accordion-header .accordion-button {
  background-color: #f9f9f9;
  line-height: 1.2;
}

.Flight-fnum {
  margin-left: 5px;
}

.Flight-inner-detail .Flight-airport-part .Flight-airport-name h4 {
  display: block;
  margin-bottom: 2px !important;
}

.Flight-disc-none {
  opacity: 0;
  margin-left: -10px;
}

.Flight-roundtrip-result .Flight-inner-detail .Flight-airport-part .Flight-airport-progress {
  width: 80px;
  margin: 0 10px;
}

.Flight-loaderGIF {
  align-items: center;
  display: flex;
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.Flight-inner-page-search .Flight-is-search-form {
  margin-bottom: 10px;
}

.Flight-detail-flight .Flight-search-result {
  background-color: #f7f7f7;
  padding-bottom: 5px;
  box-shadow: none;
}

/* .Flight-detail-flight .Flight-book-flight {
  display: none !important;
} */

.Flight-detail-flight .detail-dblock {
  display: block !important;
}

.Flight-addon-wrap .Flight-sm-btn {
  width: max-content;
  overflow: hidden;
}

.addon-top-tab .nav.nav-tabs {
  margin: 0 15px 15px 0;
  border-bottom: none;
}

.Flight-border-dashed:after,
.Flight-border-dashed:before,
.Flight-custom-modal-bg:after,
.Flight-custom-modal-bg:before,
.circle-crop:after,
.circle-crop:before {
  content: "";
  position: absolute;
  z-index: 1;
  width: 7px;
  height: 12px;
  background-color: #7f7f7f;
}

.circle-crop:after {
  right: -1px;
  bottom: -23px;
  border-radius: 10px 0 0 10px;
}

.circle-crop:before {
  left: -1px;
  bottom: -23px;
  border-radius: 0 10px 10px 0;
}

.Flight-border-dashed:after,
.Flight-custom-modal-bg:after {
  left: -1px;
  bottom: -7px;
  border-radius: 0 10px 10px 0;
}

.Flight-border-dashed:before,
.Flight-custom-modal-bg:before {
  right: -1px;
  bottom: -7px;
  border-radius: 10px 0 0 10px;
}

.Flight-oneway {
  grid-template-columns: 2fr 1fr 1fr 1fr 2fr;
}

.Flight-oneway .air-div {
  text-align: left;
  padding-left: 65px;
}

.Flight-oneway .price-div {
  text-align: right;
  padding-right: 80px;
}

.Flight-twoway {
  grid-template-columns: 2fr 1.5fr 1.5fr 1.5fr 2fr;
}

.Flight-twoway .air-div {
  text-align: left;
  padding-left: 16px;
}

.Flight-int .price-div,
.Flight-twoway .price-div {
  text-align: right;
  padding-right: 16px;
}

.Flight-int {
  grid-template-columns: 1fr 1fr;
}

.Flight-selector-box-flight .form-check .form-check-label {
  font-weight: 700;
  margin-left: 5px;
}

.Flight-selector-box-flight .form-check {
  margin-bottom: 7px;
}

.Flight-selector-box-flight .Flight-room-cls {
  padding: 10px 5px;
}

.Flight-loaderGIF img {
  width: 15% !important;
}

.Flight-row.row>[class*="col-"] {
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
}

.Flight-pagination .page-item.active .page-link {
  background-color: #f9f9f9;
  border-color: #6900a0;
}

.Flight-pagination .page-link {
  color: #6900a0 !important;
  padding: 4px 10px !important;
  border-radius: 5px;
  font-size: 14px !important;
  margin: 0 2px;
}

.Flight-pagination a.page-link:hover {
  background-color: #ececec;
}

.Flight-detail-wrap .accordion-item {
  margin-top: 8px;
}

.writing-mode-hori {
  writing-mode: horizontal-tb; -webkit-writing-mode: horizontal-tb;
 
}

.Flight-seat-lout{display:block; overflow-x:auto; white-space:nowrap; writing-mode:vertical-lr; text-orientation:upright; -webkit-text-orientation: upright;}

.Flight-seat-lout td img,
.Flight-list-unstyled li img {
  padding: 0;
  width: 28px;
}

.Flight-home-main-container .Flight-custom-select.d-lg-flex {
  display: block !important;
}

.fchild-mb p {
  margin-bottom: 5px;
}

.Flight-shadow-box .Flight-search-result {
  border: none;
  margin-bottom: 0;
}

.Flight-font-ocity08 {
  color:rgba(0, 0, 0, .60);
  font-weight: 600; margin-bottom:2px; font-size:14px;
}

.Flight-white-btn {
  color: #6900a0 !important;
  font-weight: 700 !important;
}

.Flight-white-btn:hover {
  background-color: #dbeeec !important;
}

.black-bg {
  background-color: #273135;
}

.black-bg h3 {
  line-height: 38px;
}

.b-Id {
  background-color: #fff;
}

.searchbox {
  right: 0;
  top: -10px;
  position: absolute
}

.detail-date {
  display: none;
}

.Flight-detail-flight .Flight-search-result .detail-date {
  display: block;
}

.popover-dark .Flight-fd-table {
  background-color: rgba(0, 0, 0, .80);
  border-radius: 5px;
  color: #fff;
}

.popover-dark.bs-popover-top>.popover-arrow::after {
  border-top-color: rgba(0, 0, 0, .80);
}

.Fligh-alert1 {
  background-color: #CDECF0;
  color: #00565F;
  border-radius: 5px;
}

.partial-note {
  background-color: #FFF2CF;
  color: #866310; border-radius:5px;
}

.Flight-ticket-tbl tr td,
.Flight-ticket-tbl tr th {
  background-color: transparent;
}

.detail-stretch {
  grid-template-columns: 1.3fr 1fr;
  line-height: 26px;
  grid-column-gap: 10px;
  grid-row-gap: 15px;
}

.bookingcard-wrap .Flight-search-inner {
  padding-bottom: 20px;
}

.detail-stretch {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.Flight-ticket-table {
  table-layout: fixed;
}

.Flight-ticket-table tr td:first-child,
.Flight-ticket-table tr th:first-child {
  width: 45%;
}

/*OGenie*/
.sec-pad {
  padding:25px 0;
}

.static-features {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-color: #3e0354;
  border-radius: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 70px;
  padding: 10px;
}

.static-features .feature1 {
  background-color: #faa619;
  border-radius: 20px;
  padding: 20px;
}

.div4 {
  grid-column-gap: 50px;
  grid-row-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.feature-carousel .swiper-container,
.feature-carousel.swiper,
.widgetSection .form-group .delete-city img {
  position: static;
}

.feature-carousel .swiper-wrapper {
  z-index: 0;
}

.destination-carousel .swiper-pagination,
.feature-carousel .swiper-pagination {
  margin-top: 15px;
  position: inherit;
}

.destination-carousel .swiper-pagination-bullet,
.feature-carousel .swiper-pagination-bullet {
  background-color: #6900a0;
  height: 10px;
  width: 10px;
}

.destination-carousel h4 {
  color: #2a2a2e;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 10px;
  text-decoration: none;
}

.destination-carousel h3 {
  font-size: 16px;
}

.bestdeal-box {
  background-color: #f3f3f5;
  border-radius: 6px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, .2);
  padding: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  transition: 0.2s;
}
.bestdeal-box:hover {
  box-shadow: none;
  transition: 0.2s;
}

.city-drop1:before {
  background-color: #fff;
}

.city-drop2:before {
  background-color: #6900a0;
}

.city-drop1:before,
.city-drop2:before {
  border: 1px solid #6900a0;
  border-radius: 10px;
  content: "";
  display: inline-block;
  height: 9px;
  margin-right: 10px;
  width: 9px;
}

.city-drop1:after {
  border-right: 2px dashed #6900a0;
  content: "";
  height: 30px;
  left: 4px;
  position: absolute;
  top: 17px;
  width: 2px;
  z-index: 0;
}

.download-block {
  background-image: url("/src/assets/images/home/download-bg.jpg");
  background-size: cover;
  border-radius: 10px;
}

.partner-inner,
.partnersldier .swiper-wrapper {
  align-items: center;
}

.partner-inner {
  background-color: #f7f7f7;
  border-radius: 5px;
  display: flex;
  height: 93px;
  justify-content: center;
}

.partnersldier .swiper-slide img {
  border-radius: 0;
}

.destination-carousel .swiper-wrapper {
  transition-timing-function: linear !important;
}

.partnersldier .swiper-slide {
  width: 240px !important;
}

.ls-circle li {
  list-style: circle;
}

.home-header {
  background-image: url("/src/assets/images/home/hpme-banner.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 350px;
  position: relative;
}

.fblcok-header,
.footer {
  background-color: #f7f7f7;
}


ul.footer-links {
  margin-bottom: 0;
  padding-left: 0;
}

.footer-links a {
  color: #666;
  font-size: 15px;
  position: relative;
  text-decoration: none;
  transition: .5s;
}

.footer-links a:hover {
  color: #000;
  padding-left: 20px;
}

.footer-links a:hover:before {
  opacity: 1;
}

.footer-links a:before {
  background-color: #6900a0;
  bottom: 0;
  content: "";
  height: 2px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: .5s;
  width: 10px;
}

.footer-heading img {
  display: none;
}

.sub-footer {
  border-top: 1px solid #c9c9c9;
  color: #666;
}

.destination-carousel a,
a:hover {
  text-decoration: none;
}

.head-txt h1 {
  font-size: 26px;
}

.head-txt h2 {
  font-size: 20px;
}

.head-txt {
  font-family: neulis_altsemibold_italic, sans-serif;
  left: 0;
  position: absolute;
  right: 0;
  top: 45px;
}

.home-main-container .Flight-widgetSection {
  margin-top: -225px;
}

.Flight-home-main-container .Flight-widgetSection {
  margin-top: -225px;
}

.Flight-widgetSection {
  background-color: #fafafa;
  border-radius: 12px;
  box-shadow: 0 2px 8px 3px rgba(0, 0, 0, .2);
  margin: 0 auto;
  padding: 30px 50px;
}

.trip-type li.active {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 hsla(0, 1%, 75%, .71);
  color: #6900a0;
  transition: .2s;
}

.roundtrip-result .logo-sec .title,
.trip-type li {
  font-size: 14px;
}

.font-bold,
.trip-type li {
  font-weight: 700 !important;
}
.Flight-widgetSection .form-group img {
  left: 0;
  padding: 5px 10px;
  position: absolute;
  top: 10px;
}
.Flight-swap-btn img.swap-icn {
  bottom: 0;
  cursor: pointer;
  left: auto;
  padding: 0;
  position: absolute;
  right: -95px;
  top: 50%;
  -webkit-transform: translate(-50%,-50%) rotate(180deg);
  transform: translate(-50%,-50%) rotate(180deg);
  transition: .5s!important;
  z-index: 1;
}
.Flight-widgetSection .Flight-date-block, .Flight-widgetSection .Flight-search-block {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}
.btn.show-detail:focus, .Flight-widgetSection .Flight-date-block .form-group .form-control, .Flight-widgetSection .form-group .search-block .form-control {
  border: none;
}
.Flight-widgetSection .form-group .form-control {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  color: #333;
  height: 51px;
  padding: 15px 12px 15px 35px;
  width: 100%;
}
.Flight-date-block:after, .Flight-search-block:after {
  background-color: #d9d9d9;
  content: "";
  height: 52px;
  left: 50%;
  position: absolute;
  top: 0;
  width: 1px;
}
.Flight-widgetSection .form-group, .with-icon, body{position: relative;}
.Flight-home-main-container .Flight-select-fare {
  background-color: #f0efef;
  border: 1px solid #fff;
}
.Flight-home-main-container .Flight-select-fare .form-check {
  border-right: 1px solid #fff;
  padding: 8px 15px 8px 38px;
}
.final-btn {
  bottom: -30px;
  left: 0;
  right: 0;
}
.Flight-widgetSection .Flight-date-block .form-group img {
  top: 13px;
}

.headerwrapper {
  height: 60px;
}

.header-right .profile-dd {background-color: #f7f7f7;
  margin-bottom: 0;
  min-height: 44px;
  color: #000;
  font-size: 12px;
  text-decoration: none;
}
.dropdown-toggle.profile-dd .rounded-circle {
  margin-right: 10px;
}
.dropdown-toggle.profile-dd:after {
  border: solid #000;
  border-width: 0 2px 2px 0;
  display: inline-block;
  margin-left: 12px;
  margin-right: 10px;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.dropdown-item.active, .dropdown-item:active, .react-datepicker__close-icon:after {
  align-items: center;
  background-color: #6900a0!important;
  display: flex!important;
  font-size: medium!important;
  justify-content: center;
}
.modal .modal-header .btn-close {
  background: url("/src/assets/images/home/close-icon.svg");
  height: 15px;
  margin: 0!important;
  opacity: 1;
  padding: 0!important;
  width: 15px;
}

.login-heading {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}
.add__button, button.btn.add__button:hover {
  background: linear-gradient(93deg,#ab57d5,#9f42ce);
}
button.btn.bck {
  left: 16px;
  min-width: auto;
  position: absolute;
  top: -17px;
}
.add__button.btn:disabled {
  background: #d6afeb;
  background: linear-gradient(0deg,#d6afeb,#d5aaeb);
  color: #fff;
}
button.btn.login-btn {
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
}
.Flight-inner-page-search {
  background-color: transparent;
  box-shadow: none;
  padding: 0 !important;
}

.Flight-inner-page-search .is-search-btn {
  width: 100%;
}

.Flight-inner-page-search .Flight-is-search-form {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  display: grid;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  grid-template-columns: 2fr 2fr 2fr 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 0;
}

.Flight-inner-page-search .Flight-selector-box-flight {
  min-width: max-content;
}

.Flight-inner-page-search .Flight-selector-box-flight:last-child.Flight-phone-right0 {
  right: 0;
}
.Flight-widgetSection.Flight-inner-page-search .Flight-swap-btn img.swap-icn {
  width: 48px;
}

.Flight-inner-page-search .Flight-ctm-slct .select__control, .Flight-inner-page-search .Flight-ctm-slct .select__control:focus {
  border: 0;
  box-shadow: none;
}
.Flight-inner-page-search .is-search-btn {
  height: 52px;
  margin-top: -2px;
  width: 100%;
}

.Flight-widgetSection.Flight-inner-page-search form .Flight-roundtrip .form-m{margin-bottom: 0;}
footer{margin-top:30px}
.getapp {
  background-color: #fff;
  border-radius: 5px;
}
.PhoneInputInput  {
  flex: 1 1;
  min-width: 0; 
  border:none;
  position: relative;
}
/* .PhoneInputInput .form-control{border:none !important;} */
.Flight-tick-stretch {
  grid-template-columns: 1.3fr 1fr 1fr;
  grid-column-gap: 8px;
  grid-row-gap:15px;
}

.db-nav.nav-pills .nav-link {
  color: #000;
  border-left: 6px solid transparent;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 600;
}
.db-nav.nav-pills .nav-link img {
  filter: grayscale(1);
}
.db-nav.nav-pills .nav-link.active img {
  filter: grayscale(0);
}
.db-nav.nav-pills .nav-link.active {
  background-color: #fff;
  color: #7f37a1;
  border-left-color: #7f37a1;
  position: relative;
}
.db-nav.nav-pills .nav-link.active:before {
  content: "";
  background-color: #fff;
  position: absolute;
  right: -6px;
  width: 6px;
  height: 100%;
  top: 0;
}
.db-profile {
  background-color: #f7f7f7;
  border-radius: 6px;
}
.db-booking .nav-tabs .nav-link {
  border-bottom: none;
  font-size: 14px;
  color: #2A2A2E; font-weight:600;
  margin-left:5px;
}
.db-booking .nav-tabs .nav-link.active {
  color: #6900a0;
  background-color: #f7f7f7;
  border-color: transparent;
}
.db-booking .tab-content{background-color:#f7f7f7; border-radius:5px;}
.db-booking .nav.nav-tabs{margin-left:10px; border-bottom:none;}

.bdate:before {
  left: 12px;
  width: 2px;
  height: 20px;
  top: 2px;
}
.dotafter:after {
  width: 4px;
  height: 4px;
  display: inline-block;
  border-radius: 2px;
  top: 7px;
  right: 1px;
}

.booking1 {
  margin-bottom: 10px; background-color:#fff; padding:0 15px;
}
.white-space-no td,
.white-space-no th {
  white-space: nowrap;
}
.dashboard-sidebar { min-height:400px;
  background-color: #f7f7f7;
  border-radius: 5px 5px 0 0;
  padding-bottom: 30px;
}
.profile-image img{width:74px; height:74px;}

.Flight-tick-stretch {
  grid-template-columns: 1.3fr 1fr 1fr;
  grid-column-gap: 8px;
  grid-row-gap:15px;
}
.multiairline-logo img + img{margin-left:-16px;}
.bkng-detl li{margin-bottom:2px;}
.bkng-detl li .data-wrap{min-width:115px; display:inline-block;}
.bd-stretch{ grid-template-columns: 1fr 7fr;
  grid-column-gap: 8px;
  grid-row-gap:15px; border-top:1px dashed #B7B7B7; margin-top:15px; padding-top:15px;}
  .logo-wid{min-width:36px; text-align:center;}
.Flight-layover-time2{bottom:-23px;}
.Flight-layover-time2:after, .Flight-layover-time2:before{display:none;}
.detail-baggage1{border-top:1px dashed #B7B7B7; padding-top:10px; margin-top:15px; padding-bottom:10px;}
.det-head{font-size:16px; font-weight:600;}

.trv-accord .trav-cnt ul{border-bottom:1px dashed #B7B7B7;padding-bottom:10px; margin-bottom:10px}
.trv-accord .trav-cnt ul:last-child{border-bottom:none; padding-bottom:0; margin-bottom:0;}
.journey-accord .accordion-item:not(:first-of-type){border-top:1px dashed #B7B7B7 !important; border-top-right-radius:0;
  border-top-left-radius:0;}
.journey-accord button.accordion-button{padding-left:0; padding-right:0;}
.journey-accord .accordion-item{margin-bottom:0;}
.fare-list li:last-child{border-top:1px dashed #b7b7b7; border-bottom: 1px dashed #b7b7b7; padding-top:10px; padding-bottom:10px; margin-top:10px}
.twoborder{border-top:1px solid #fff; border-bottom:1px solid #D9D9D9; padding:10px 0;}
.upload-img{border:1px solid #6900a0; color:#6900a0; font-size:12px; font-weight: 600; border-radius:4px; padding:2px 15px; margin-top:15px}
.Flight-btn-outline-gray{font-size:14px !important; border:1px solid #49454f !important; color:#49454f !important}
.popup-btn{min-width:130px}
.profile-icons{position:absolute; left:15px; top:50%; transform:translateY(-50%); z-index:9;}
.profile-modal .form-control{padding-left:40px;}
.bdate:before,
.dotafter:after {
  content: "";
  background-color: #707070;
  position: absolute;
}
.bdate:before {
  left: 12px;
  width: 2px;
  height: 20px;
  top: 2px;
}
.dotafter:after {
  width: 4px;
  height: 4px;
  display: inline-block;
  border-radius: 2px;
  top: 7px;
  right: 1px;
}
.tab-wrapper{padding:15px}
.stop-li:before{content:""; width:7px; height:7px; background-color:#6900a0; display:inline-block; border-radius:10px; margin-right:5px;}





.tl-depcity:after{content:""; width:7px; height:7px; background-color:#000; border-radius:10px; display:inline-block; position:absolute; top:10px; left:7px;}
.tl-depcity1:before{content:""; top:0; bottom:0; position:absolute; border-left:1px dashed #1D1B1F; width:1px; left:10px;}
.tl-depcity{position:relative; padding-left:30px; margin-top:-8px; padding-bottom:8px;}
.tl-depcity.tl-depcity1{margin-top:0}
.timeline .tl-depcity:first-child:before{top:10px}
.bt-time{padding:20px 0}

.total-time{margin-left: 30px;}
.total-time img{width:12px;}
.viewdetail-modal .modal-header .btn-close{position:absolute; z-index:9}
.time-total{background-color:#000; color:#fff}
.fl-detail{border-top:1px dashed #E7E0EC; border-bottom:1px dashed #E7E0EC}
.booking-summary{border-top:1px dashed #E7E0EC; border-bottom:1px dashed #E7E0EC}
.dtl-modal-body section{border-top:1px dashed #E7E0EC; padding-top:20px}
.dtl-modal-body section:first-child{border-top:none; padding-top:0}
.booking1 .sector-info{border-bottom:1px solid #dee2e6; margin-bottom:16px;}
.flight-rt-logo img{margin-left:-20px;}
.flight-rt-logo img:first-child{margin-left:0;}
.booking-day{background-color:#fff;}
.booking-list li:first-child{border-top:none}
.booking-list li:last-child{border-bottom:none;}
.flight-info-wrap .Flight-reqClass:last-child .flight-info{padding-bottom:15px !important;}
.rt-cncl .table-responsive, .border-tbl .table-responsive{border:1px solid #ddd; border-radius:4px; overflow:hidden;}
.db-tab-container{min-height:400px;}
.loader-wrapper.img-gif {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.loader-wrapper {
  height: 100vh;
  width: 100vw;
  background-color: white;
  display: block;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  left: 0; right:0; bottom:0;
  z-index: 99999;
  top: 0;
}
.loader-wrapper.img-gif img {
  width: 9%;
}
.ps-note{border:none}
.btn:disabled{border:1px solid #333 !important; background:transparent; color:#333 !important}
.main-heading1{font-size:40px;}
.addon-count button.btn:disabled{border:none !important}
.Flight-pax-calc.Flight-val-plus .btn:disabled{color:#fff !important}
.Flight-stopp:hover{background-color:#e8def8; color:#6900a0}
.Flight-stopp:hover .Flight-wht-icon{display:inline-block;}
.Flight-stopp:hover .Flight-g-icon{display:none;}
.hideDiv {display:none;}
.ellipsis-text{text-overflow:ellipsis; overflow:hidden; white-space:nowrap;}
.rounded-carusel img{border-radius:12px;}
.db-bdeadcrumb a{color:#989898; text-decoration:none;}
.db-bdeadcrumb .breadcrumb-item + .breadcrumb-item::before{content: ">"; color:#989898}
.db-bdeadcrumb .activ-breadcrum a{color:#000}
.Flight-clear-filter{font-size:14px;}
.Flight-filterouter .multi-range-slider .labels{font-weight:600;}
.header-right .dropdown-menu{border:none; box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.13); min-width:200px; overflow:hidden;}
.header-right .dropdown-menu .dropdown-item{padding-top:15px; padding-bottom:15px;}
.fad-content .accordion-item .accordion-header .accordion-button{padding-left:16px; text-transform:capitalize;}
.btn.delete-btn, .btn.delete-btn:hover{border-color:#DB1E49; color:#DB1E49; font-size:14px;}
.roundtrip-rpf-table, .Flight-roundtrip-result .roundtrip-rpf-table2{display:none;}
.Flight-roundtrip-result .roundtrip-rpf-table{display:block;}
/*static pages*/

.aboutus-seperator {
  /* background-image: url(/public/images/home/aboutus-seperator.svg); */
  width: 100%;
  height: 42px;
  opacity: 0.6;
}
.abt-wrap h2 {
  font-size: 28px;
  font-weight: 700;
  font-style: italic;
}
.tc-leftbar .nav-pills .nav-link.active,
.tc-leftbar .nav-pills .show > .nav-link {
  background-color: #fff;
  color: #6900a0;
}

.home-main-container .custom-select,
.tc-leftbar .nav-link:hover {
  color: #000;
}
.single-line-tc {
  margin-bottom: 30px;
  text-align: justify;
}
.single-line-tc h3 {
  font-size: 20px; text-transform:capitalize;
  font-weight: 600;
  margin-bottom: 10px;
}
.single-line-tc h5 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}
.generalterms li,
.single-line-tc p,
.terms-innerconditions > li {
  font-size: 14px;
  font-family: mallorybook, sans-serif;
}
.terms-innerconditions > li:after {
  position: absolute;
  left: 0;
  top: 8px;
  width: 8px;
  height: 8px;
  content: "";
  background-color: #666;
  border-radius: 5px;
}
.generalterms li,
.terms-innerconditions > li {
  margin: 10px 0 0 !important;
  position: relative;
  padding-left: 28px;
}
.ctm-slct.depart-slct .select__control,
.terms-innerconditions2 {
  padding-left: 30px;
}
.terms-innerconditions2 > li {
  font-family: mallorybook, sans-serif;
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
}
.terms-innerconditions2 > li:after {
  position: absolute;
  left: 0;
  top: 8px;
  width: 8px;
  height: 8px;
  content: "";
  border: 1px solid #666;
  border-radius: 5px;
}
.web-checkin-wrap {
  background-color: #fff;
  box-shadow: 0 1px 6px 0 rgb(0 0 0/20%);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 6px 0 rgb(0 0 0/20%);
  -moz-box-shadow: 0 1px 6px 0 rgba(0,0,0,0.2);
}
.web-checkin-wrap .nav-link{color:#000}
.webcheckin-navtab {
border: 1px solid#e7e7e7;
margin-bottom: -1px;
background-color: #fff;
position: relative; margin:0 20px;
z-index: 1;
}
.webcheckin-navtab.nav-tabs .nav-item {
margin-bottom: 0;
}
.webcheckin-navtab.nav-tabs .nav-link {
border: none;
border-bottom: 3px solid transparent;
font-size: 14px;
color: #333;
}

.webcheckin-navtab.nav-tabs .nav-link.active,
 .webcheckin-navtab.nav-tabs .nav-item.show .nav-link,
 .webcheckin-navtab.nav-tabs .nav-link.active:hover {
border-bottom-color: #6900a0;
}
.web-checkin-wrap .tab-content {
border: 1px solid#e7e7e7;
border-radius: 0;
margin-top: -35px;
padding:50px 30px 30px;
}
.list-decimal li {
display: list-item;
margin-bottom: 5px;
}
.webcheckin-navtab.nav-tabs .nav-link:hover{border-bottom:3px solid transparent;}
.tc-leftbar {
  background-color: #f7f7f7;
}
.tc-leftbar .nav-pills .nav-link.active,
.tc-leftbar .nav-pills .show > .nav-link {
  background-color: #fff;
  color: #6900a0;
}

.home-main-container .custom-select {
  color: #000;
}
.abt-img {
  -webkit-box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
  border-radius: 4px;
  width: 100%;
}

/*21 nov 2023*/
.mobile-navigation{position:fixed; background-color: #fff; left:0; right:0; bottom:0; z-index:12; padding-top:12px; padding-bottom:15px; box-shadow:0px -5px 23px 0px rgb(0 0 0 / 7%);}
.height{height:57px;}


@media (min-width: 576px) {
  .Flight-container.container {
    max-width: 540px !important;
  }
}

@media (min-width: 768px) {
  .Flight-container.container {
    max-width: 720px !important;
  }
}

@media (min-width: 992px) {

  .Flight-container-lg.container-lg,
  .Flight-container.container {
    max-width: 960px !important;
  }
}

@media (min-width: 1200px) {

  .Flight-container-lg.container-lg,
  .Flight-container-xl.container-xl,
  .Flight-container.container {
    max-width: 1140px !important;
  }
}

@media (min-width: 1400px) {

  .Flight-container-lg.container-lg,
  .Flight-container-xl.container-xl,
  .Flight-container-xxl.container-xxl,
  .Flight-container.container {
    max-width: 1320px !important;
  }
}

@media (min-width: 768px) {
  .Flight-widgetSection .form-group img.pln-icn:nth-child(2) {
    left: 10px;
  }
  .Flight-widgetSection.Flight-inner-page-search .Flight-swap-btn img.swap-icn {
    left: 100%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%) rotate(180deg);
    transform: translate(-50%,-50%) rotate(180deg);
}
  .swap-icn.swap-city {
    transform: translate(-50%, -50%) rotate(0) !important;
    transition: 0.5s !important;
  }


}

@media (min-width: 576px) {
  .Flight-modal .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
}

@media (min-width: 1461px) {
  .container {
    max-width: 1240px;
  }
}

@media (max-width: 1400px) {
  .Flight-hide-popular-filter {
    min-width: 273px;
  }
  .loader-wrapper.img-gif img {
    width:15%;
}
}

@media (max-width: 1350px) {
  .Flight-roundtrip-result .Flight-detail-wrap .Flight-nav-tabs .nav-link {
    font-size: 12px;
  }
}

@media (max-width: 1200px) {
  .Flight-hide-popular-filter {
    min-width: initial;
    max-width: 100%;
    width: 24%;
  }

  .Flight-roundtrip-result .Flight-detail-wrap .accordion {
    display: block !important;
  }

  .Flight-roundtrip-result .Flight-detail-wrap .flight-fd-inner {
    display: none !important;
  }

  .Flight-sorting {
    font-size: 12px;
  }

  .Flight-airport-part .Flight-airport-progress {
    margin: 0 10px;
  }

  .container {
    max-width: 100%;
  }

  .Flight-btn-detail li {
    padding-right: 10px;
    margin-right: 10px;
  }

  body {
    font-size: 14px;
  }

  .Flight-font20 {
    font-size: 18px !important;
  }

  .Flight-roundtrip-result .Flight-search-inner {
    padding: 12px 12px 0;
  }

  .Flight-roundtrip-result .Flight-search-inner .rt-col-7 {
    width: 58.33333333%;
  }

  .Flight-roundtrip-result .Flight-search-inner .rt-col-5 {
    width: 41.66666667%;
    order: 2 !important;
  }

  .Flight-roundtrip-result .Flight-search-inner .rt-order-3 {
    order: 3 !important;
    width: 100%;
  }

  .Flight-roundtrip-result .Flight-inner-detail {
    grid-template-columns: 1fr;
  }

  .Flight-roundtrip-result .Flight-inner-detail .Flight-fnum {
    display: inline;
    margin-left: 6px !important;
  }

  .Flight-roundtrip-result .Flight-inner-detail .Flight-logo-sec {
    margin-bottom: 12px;
  }

  .Flight-roundtrip-result .Flight-inner-detail .Flight-airport-part .Flight-airport-progress {
    width: 90px;
  }

  .Flight-twoway {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }


  .detail-stretch {
    grid-template-columns: 1fr 1fr 1fr;
  }

}

@media (max-width: 1024px) {
  .Flight-mainContainer nav ul li a {
    font-size: 14px;
  }
  .abt-wrap h2{font-size:22px;}

  .Flight-twoway .Flight-sorting.sorted img {
    display: none;
  }
  .loader-wrapper.img-gif img {
    width:25%;
}
.main-heading1{font-size:38px;}
.grand-total{padding-left:0;}
}

@media (max-width: 1000px) {

  .Flight-loaderGIF.search-loader,
  .Flight-loaderGIF.search-loader1 {
    height: auto;
  }


  .Flight-roundtrip-result .Flight-search-inner {
    padding: 15px 8px 0;
  }

  .Flight-sticky_nav {
    display: block;
  }

  .Flight-selection-bar {
    background-color: transparent;
    border-radius: 0;
    border: none;
    padding: 5px 10px;
    display: block;
    line-height: 18px;
    margin-bottom: 0;
    border-bottom: 1px solid #eeeded;
  }

  .Flight-selection-bar .details {
    display: none;
  }
}

@media (max-width: 991px) {
  .Flight-home-main-container .Flight-custom-select.d-lg-flex {
    display: none !important;
  }
  .ps-note{border-bottom:8px solid #ecf5f5 ;}
  .seperator-ph {
    border-top: 2px dashed #e4e4e4;
    padding-top: 15px;
  }

  .Flight-inner-page-search .Flight-is-search-form {
    grid-template-columns: 1fr;
  }

  .Flight-inner-page-search .Flight-sticky-searchbar {
    position: fixed;
    display: block;
    top: -1000px;
    left: 0;
    right: 0;
    overflow-y: auto;
    transition: 0.5s;
    z-index: 99999;background-color: #fafafa;
    padding: 10px 15px 20px;
  }

  .Flight-inner-page-search .is-search-btn {
    width: 200px;
    margin: 20px auto 0;
  }
  .sec-pad {
    padding: 15px 0;
}

  .book_panel .Flight-box-stretch {
    grid-template-columns: 1fr 1fr;
  }

  .Flight-rt-int-sbar {
    display: none !important;
  }

  .Flight-roundtrip-result .Flight-search-rslt {
    overflow-y: auto;
    height: 100vh;
  }

  .Flight-inner-page-search .Flight-selector-box-flight {
    width: 100%;
  }

  .Flight-vm-table2 table thead tr:first-child:after {
    top: 63px;
  }

  .Flight-mainContainer nav ul {
    transition: width 0.4s;
  }

  .Flight-table-div {
    overflow-x: scroll;
  }

  .Flight-rt-int-flights .Flight-airport-part .Flight-airport-name h4 {
    display: block;
  }

  .Flight-overlay {
    display: none;
    position: absolute;
    background-color: #0000001c;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99;
    overflow: hidden;
  }

  .Flight-wrapper header {
    position: initial;
  }

  .Flight-wrapper {
    padding-top: 0;
  }

  .Flight-shadow-box {
    border-color: #ecf5f5;
    border-radius: 0;
    border-width: 0 0 8px;
  }

  .Flight-faretype {
    border-top: none;
  }

  .Flight-addon-wrap .Flight-addon-border {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
  }

  .Flight-addon-wrap>div:last-child {
    border-bottom: 1px solid #d9d9d9 !important;
  }

  .Flight-shadow-box .Flight-search-result {
    border: 1px solid #ddd;
    margin-bottom: 15px;
  }

  .Flight-inner-detail2.Flight-inner-detail3 {
    grid-template-columns: 1fr;
  }
  .Flight-widgetSection.Flight-inner-page-search form .Flight-roundtrip .form-m {
    margin-bottom: 12px;
}
.main-heading1{font-size:34px;}
.db-btm-btns{  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  display: grid;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; grid-column-gap:10px;  grid-row-gap:10px; grid-auto-columns: 1fr; grid-template-columns: 5fr 5fr 5fr;
}

}

@media (max-width: 990px) {
  .Flight-wrapper {
    margin-bottom: -190px;
  }
  .push,
  footer.footer-bg {
    height: 190px;
  }
  .Flight-home-heading {
    font-size: 36px;
  }

  .Flight-home-para {
    font-size: 18px;
    line-height: 26px;
  }

  .mobile-filter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-top: 1px solid #fafafa;
  }

  .flight-detail-sec.book_panel {
    height: 60px;
  }

  .Flight-left-sidebar,
  .Flight-trip_icon img {
    width: 20px;
  }

  .mob-deta .Flight-price {
    line-height: 14px;
  }

  .Flight-airport-part .Flight-airport-progress {
    margin: 0 10px;
  }

  .Flight-left-sidebar {
    position: fixed;
    width: 100%;
    left: -101%;
    top: 0;
    margin-top: 0;
    border: none;
    z-index: 99999;
    bottom: 0;
  }

  .Flight-left-sidebar .Flight-back-btn {
    display: inline-block;
  }

  .Flight-roundtrip-result .Flight-search-result {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #ddd;
    margin-bottom: 0;
  }

  .rt-border-end {
    border-right: 1px solid #ddd;
  }

  .Flight-roundtrip-result .flight-info-wrap .flight-info {
    padding: 12px 10px 25px;
  }

  .Flight-roundtrip-result .Flight-inner-detail .Flight-fnum {
    display: inline-block;
    margin-left: 5px !important;
  }

  .Flight-roundtrip-result .Flight-inner-detail .Flight-logo-sec {
    margin-bottom: 15px;
  }

  .Flight-roundtrip-result .rt-container,
  .Flight-roundtrip-result .rt-row {
    margin: 0 !important;
    padding: 0 !important;
  }

  .Flight-home-graphic {
    display: none;
  }

  .Flight-home-main-container .Flight-home-graphic {
    display: block;
  }

  .detail-stretch {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .web-checkin-wrap .tab-content{padding:35px 12px 0; border:none; font-size:13px;}
  .webcheckin-navtab.nav-tabs .nav-link{font-size:13px; padding-left:5px; padding-right:5px;}
  .webcheckin-navtab{border:none; margin:0}
  
}

@media (max-width: 767px) {
  .dashboard-sidebar {min-height:auto;}
  .Flight-filterouter{margin-top:12px}
  .fad-content .accordion-item .accordion-header .accordion-button::after{background-size:16px;}
  .searchbox {
    position: relative;
  }
  .loader-wrapper.img-gif img {
    width:30%;
}
  .Flight-btn-detail li {
    border: none;
  }

  .Flight-inner-page-search .Flight-is-search-form,
  .Flight-logo-sec img,
  .Flight-roundtrip-result .Flight-search-inner .rt-order-3,
  .Flight-selection-bar {
    margin-bottom: 0;
  }

  .Flight-IATA-code,
  .Flight-airport-part .Flight-airport-progress .stop {
    font-weight: 600;
  }

  .Flight-airport-part .Flight-airport-progress .stop {
    bottom: -7px;
  }

  .Flight-mainContainer nav ul li a {
    padding: 6px 10px;
  }

  .Flight-phn-book-btn {
    padding: 3px !important;
  }

  button.Flight-btn {
    min-width: 76px;
  }

  .Flight-addon-tabs .nav-tabs {
    margin-bottom: 10px;
  }

  .Flight-inner-page-search .home-search-btn {
    top: 360px;
  }

  .Flight-widgetSection.Flight-inner-page-search .Flight-swap-btn img.swap-icn {
  margin-top:7.5px;
  }
  .Flight-swap-btn img.swap-icn {
    margin-right: -23px;
    margin-top: 9.5px;
    padding: 0;
    right: 50%;
    top: 50%;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    width: 46px;
}
  .Flight-widgetSection form .Flight-roundtrip .form-m,
  .form-m2 {
    margin-bottom: 12px;
  }

  .Flight-inner-page-search .form-m2 {
    margin-bottom: 8px;
  }

  .Flight-sticky_nav.showheader {
    top: 0;
  }

  .Flight-home-main-container .Flight-home-graphic {
    width: 35%;
    top: 100px;
  }

  .Flight-wrapper {
    padding-top: 0;
    margin-bottom: -161px;
  }

  .Flight-ctm-slct-mdl .select__control {
    border: 0 !important;
  }

  .main-heading1 {
    font-size: 28px;
}

  .push,
  footer.footer-bg {
    height: 161px;
  }

  .Flight-custom-select,
  .Flight-roundtrip-result .Flight-search-inner .Flight-rt-mob-show {
    display: block;
  }

  .Flight-roundtrip-result .Flight-search-inner .Flight-show-detail,
  .Flight-search-block:after,
  .fcls {
    display: none;
  }

  .Flight-depart-slct.Flight-ctm-slct .select__control {
    padding-left: 35px;
  }

  .Flight-footer-logo {
    width: 120px;
  }

  .Flight-ctm-slct .select__control .select__indicator,
  .Flight-depart-slct.Flight-ctm-slct .select__control .select__indicator {
    margin-right: 5px;
  }

  .Flight-ctm-slct .select__control {
    padding-left: 35px;
    border: 1px solid #d9d9d9;
  }

  .Flight-home-heading {
    font-size: 24px;
  }

  .Flight-viewdetail-arrow:after {
    margin-top: -4px;
  }

  button.Flight-search-flight-btn {
    height: 50px;
    width: 250px;
  }

  .Flight-widgetSection .form-group .Flight-search-block .form-control {
    border: 1px solid #d9d9d9;
  }
  .Flight-widgetSection .Flight-search-block {
    border: none;
    background-color: transparent;
  }

  .react-datepicker-popper {
    width: 320px;
  }
  .final-btn {
    bottom: -25px;
}
.head-txt {
  top: 25px;
}
  .react-datepicker__day {
    height: 40px;
  }

  .react-datepicker__day span {
    padding-top: 2px;
    font-size: 12px;
  }

  .react-datepicker__day--selected p {
    font-size: 9px;
  }

  .Flight-widgetSection .Flight-date-block.Flight-phone-date-block .form-group .form-control,
  .Flight-widgetSection.inner-page-search .Flight-custom-select .form-select {
    padding-right: 26px !important;
    text-overflow: ellipsis;
  }

  .Flight-search-flight-btn,
  .Flight-widgetSection.Flight-result-widget .Flight-search-block:after {
    height: 44px;
  }

  .Flight-widgetSection.inner-page-search .form-group .Flight-phone-date-block .Flight-pax-arrow img {
    padding: 5px;
    width: 20px;
    filter: contrast(300%);
  }

  .Flight-result-widget.Flight-widgetSection {
    padding: 0;
  }



  .Flight-widgetSection {
    padding-left: 15px;
    padding-right: 15px;
  }

  .dropdown-item {
    font-size: 14px;
  }

  .d-inline-flex.Flight-select-fare {
    display: none !important;
  }

  .Flight-font18 {
    font-size: 16px !important;
  }

  .Flight-btn {
    font-size: 13px;
    min-width: 95px;
  }

  .Flight-search-inner {
    padding: 10px 10px 0;
  }

  .Flight-inner-detail,
  .Flight-inner-detail2 {
    grid-template-columns: 1fr;
  }

  .Flight-detail-wrap .nav-link {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }

  .Flight-detail-wrap .Flight-nav-tabs {
    flex-wrap: inherit;
    text-align: center;
  }

  .Flight-line-seperation {
    height: 16px;
  }

  .Flight-detail-flight .detail-baggage {
    display: block !important;
  }

  .Flight-inner-detail3 .Flight-airport-part .Flight-airport-progress {
    width: 70px;
  }

  .b-dot-none:after {
    display: none;
  }

  .detail-stretch {
    grid-template-columns: 1.3fr 1fr;
  }
  .static-features {
    margin-top:40px;
}
.div4, .static-features {
  grid-template-columns: 1fr;
}
.partnersldier .swiper-slide {
  width: 200px!important;
}
.partner-inner {
  height: 70px;
}
.head-txt h1 {
  font-size: 20px;
}
.head-txt h2 {
  font-size: 16px;
}
.swap-icn.swap-city {
  transform: rotate(270deg)!important;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
}
.Flight-inner-page-search .Flight-ctm-slct .select__control {
  border: 1px solid #d9d9d9;
}
.Flight-inner-page-search .form-m2 {
  margin-bottom: 12px;
}
.header-right .profile-dd {
  background-color: initial;
  height: 34px;
  min-width: auto;
}
.Flight-font-ocity08 {font-size:13px; margin-bottom:0;}
  .Flight-font15{font-size:14px !important;}
  .profile-image img{width:50px; height:50px;}
  .db-nav.nav-pills .nav-link.active:before{display:none;}
  .db-nav.nav-pills .nav-link{border-left:none;}
  .db-nav.nav-pills .nav-link.active{background-color:#f7f7f7; border-bottom-left-radius:0; border-top-right-radius:5px; color:#000}
  .db-nav.nav-pills .nav-link img{display:none;}
  .dashboard-sidebar{background-color:transparent; padding-bottom:0;}
  .dashboard-sidebar .twoborder{border-bottom:none;}
  .db-booking .nav.nav-tabs{margin-left:0; background-color:#f7f7f7; border-top-left-radius:5px; border-top-right-radius:5px; padding-top:10px;}
  .db-booking .tab-content{border-top-left-radius:0; border-top-right-radius:0;}
  .db-booking .nav-tabs .nav-link{margin-left:0;}
  .db-booking .nav-tabs .nav-link.active{border-bottom:2px solid #6900a0;}
  .ap-part2{grid-template-columns:1fr}
  .ap-part2 .Flight-fliStopsSep{display:none;}
  .ap-part2.Flight-airport-part .Flight-airport-progress .stop{position:initial; transform:translateX(0%); display:inline;}
  .ap-part2 .Flight-IATA-code{display:inline-block;}
  .ap-part2 .Flight-IATA-code:after{content:"|"; margin:0 4px}
  .ap-part2.Flight-airport-part .Flight-airport-progress{width:initial; text-align:start !important; margin:10px 0}
  .booking1{padding:0 10px;}
  .tab-wrapper{padding:10px}
  .abt-wrap h2{font-size:18px;}
  .mobile-faq .dropdown-menu{width:100%; border:none;  box-shadow:0px 0px 10px 0px rgba(0,0,0,0.13);}
  .mobile-faq .dropdown-toggle{font-size:14px; display:flex; align-items:center; justify-content:space-between; }
 

  .web-checkin-wrap img{width:140px;}
  .footer-menu-items {
    display: none; /* Hide the dropdown by default */
  }
  .footer-menu-items.open {
    display: block; /* Display the dropdown when .open class is applied */
  }
}

@media (max-width: 575px) {
  .Flight-addon-tabs .nav-tabs .nav-link {
    padding: 5px 10px;
  }
  .loader-wrapper.img-gif img {
    width:40%;
}
  .Flight-addon-tabs .tab-content .nav-tabs .nav-link {
    padding: 5px;
  }

  .Flight-selector-box-flight .Flight-room-cls .Flight-qty-box .input-group .btn {
    font-size: 18px;
  }

  .Flight-btn-detail li {
    border: none;
    padding-right: 0;
    margin-right: 0;
  }

  .Flight-inner-page-search .Flight-selector-box-flight,
  .Flight-selector-box-flight {
    min-width: 200%;
  }

  .Flight-airport-part .Flight-airport-progress {
    width: 100px;
  }

  .Flight-inner-detail .Flight-airport-part .Flight-airport-progress {
    width: 80px;
  }

  .ph-in {
    line-height: 14px;
  }

  .Flight-loaderGIF img {
    width: 50% !important;
  }

  button.accordion-button {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .Flight-roundtrip-result .Flight-search-inner .bookFlightDetail {
    display: block;
  }

  .Flight-roundtrip-result .Flight-logo-sec .title {
    font-size: 12px;
  }

  .select__menu {
    width: 100% !important;
  }

  .Flight-phone-right0 {
    right: 0;
  }

  .Flight-roundtrip-result .Flight-search-inner {
    padding: 10px 8px 0 10px;
  }

  .Flight-trip_icon img {
    width: 15px;
  }

  .Flight-search-rslt .Flight-search-result .Flight-inner-detail .Flight-fnum {
    display: inline;
  }

  .Flight-roundtrip-result .Flight-logo-sec img {
    width: 24px;
  }

  .Flight-roundtrip-result .Flight-search-inner .Flight-btn-detail {
    display: none;
  }

  .Flight-font16 {
    font-size: 14px !important;
  }
  .Flight-font14 {
    font-size: 13px !important;
  }
  .cancel-booking-block .Flight-airport-part .Flight-airport-progress {
    width: 70px;
  }

  .bookingcard-wrap .Flight-logo-sec img {
    width: 26px;
  }

  .bookingcard-wrap .Flight-search-inner {
    padding-left: 15px;
    padding-right: 15px;
  }

  .bookingcard-wrap .Flight-airport-part .Flight-airport-progress {
    width: 90px;
  }
  .Flight-search-result {
    border-color: #e7e0ec;
}
.flight-info-wrap .flight-info{padding:12px;}
.Flight-reqClass .flight-info{padding:12px 12px 20px !important;}
.partial-note {border-radius:0;}
.db-btm-btns{grid-auto-columns:auto; grid-template-columns:auto;}
.db-btm-btns div:nth-child(1){grid-column-start:1; grid-column-end:2;}
.db-btm-btns div:nth-child(2){grid-column-start:2; grid-column-end:3;}
.db-btm-btns div:nth-child(3){grid-column-start:3; grid-column-end:5;}

}

.Flight-Booked {
  color: green;
}

.Flight-progress {
  color: yellow;
}

.Flight-processed {
  color: rgb(225, 131, 0);
}

.Flight-Cancelled {
  color: rgb(184, 91, 91);
}

.Flight-Failed {
  color: red;
}

/* shekhar start 17-aug */
.select__control--is-focused, .select__control--is-focused:hover{border: 1px solid #ccc !important; box-shadow: none !important;}
.select__control--menu-is-open svg{fill: #6900a0;}
.Flight-is-search-form .select__control--is-focused{border: 0 !important;}
#basic-addon2 {position: absolute;right: 0;height: 100%;border: 0;background: transparent;}
#basic-addon2:before {content: '';border-left: 1px solid #C9C9C9;width: 1px;height: 20px;}
.new-letter-btn:hover {color: #6900a0 !important;}

.guideline {
  background: transparent radial-gradient(closest-side at 50% 50%, #690DA0 0%, #57187c 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;padding: 20px;}